.testimonials__container {
    max-width: 700px;
    margin: auto;
}

.testimonial__item {
    text-align: center;
    padding: 0 1.25rem 1.25rem;
}

.thumb {
    height: 5.6rem;
    width: 5.6rem;
    margin: 0 auto;
}

.testimonials__title {
    margin-top: 0.75rem;
}

.subtitle {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.comment {
    background-color: var(--container-color);
    padding: 1.875rem;
    margin-top: 1.5rem;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    position: relative;
    margin-bottom: 3rem;
}

.comment::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--container-color);
    position: absolute;
    left: 49.5%;
    top: -0.625rem;
    transform: translateX(-7.5px);
}

.swiper-pagination-bullet {
    width: 0.5rem !important;
    height: 0.375rem !important;
    background-color: var(--first-color) !important;
    opacity: 1 !important;
}

.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0.2rem !important;
}

.swiper-pagination-bullet-active {
    width: 1.2rem !important;
    height: 0.375rem !important;
    border-radius: 0.5rem !important;
}