.blog__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.blog__card {
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.blog__thumb {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.blog__img {
    transform: scale(1);
    transition: .3s;
}

.blog__thumb:hover .blog__img {
    transform: scale(1.1);
}

.blog__category {
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    position: absolute;
    top: 0;
    left: 1.25rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.125rem 0.5rem;
    transition: .3s;
    z-index: 1;
}

.blog__details {
    background-color: var(--container-color);
    padding: 1.25rem;
}

.blog__title {
    font-size: var(--h3-font-size);
    margin-bottom: 0.5rem;
    margin-left: 0.875rem;
}

.blog__meta {
    display: flex;
    column-gap: 0.6rem;
    font-size: var(--small-font-size);
    color: #8b88b1;
    margin-left: 0.875rem;
    margin-bottom: 0.5rem;
}

.blog__dot {
    font-weight: var(--font-bold);
}

.blog__btn {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 1.875rem;
    width: 33.3%;
    margin-left: 33.3%;
}