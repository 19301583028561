.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0,0,0,0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

.nav__logo_img{
    width: 100px;
}


.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav__link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: .3s;
}

.nav__link:hover {
    color: hsl(43, 100%, 68%);
}

.copyright {
    color : hsl(245, 15%, 65%);
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
}



/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }